@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}
.content-dashboard tr:not(:last-child) {
  border-bottom: 1px solid #c7afe4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  scroll-behavior: smooth;
}
.input {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.inter {
  font-family: inter;
}

.albert {
  font-family: Albert Sans;
}
.poppins {
  font-family: Poppins;
}

.check :checked + div::after {
  content: "✔";
  position: absolute;

  width: fit-content;
  height: fit-content;
  top: 6px;
  right: 16px;
  font-size: 20px;
  color: white;
}
.check :checked + div {
  border: 1px solid #8652c7;
}
.wrap::-webkit-scrollbar{
  display: none;
}
.bran::-webkit-scrollbar{
  display: none;
}

.clip::-webkit-scrollbar{
  display: none;
}

.grid-work .dev:first-child {
  margin-top: 70px;
  
}

.grid-work .dev:nth-child(even):not(:nth-child(2)) {
  position: relative;
  top: -68px;
}

@media (min-width: 640px) {
  .grid-work .dev:nth-child(even):not(:nth-child(2)) {
    position: static;
    top: 0;
  }
  .grid-work .dev:first-child {
    margin-top: 0;
  }
}
